<script lang="ts">
import layout from '~/consumer/layouts/DefaultLayout.vue'
export default { layout }

</script>
<script setup lang="ts">import { ref as _ref } from 'vue';

import loginBg from '@consumer/assets/images/login-bg.png'
import EmailForm from '@consumer/modules/login/EmailForm.vue'
import PasswordForm from '@consumer/modules/login/PasswordForm.vue'
import SignupLanding from '@consumer/modules/login/SignupLanding.vue'
import CorporateLanding from '@consumer/modules/login/CorporateLanding.vue'

defineProps <{ referer?: string; authenticationError?: string; errors?: any }>()

const credentials = _ref<{
  email?: string
  password?: string
  recaptcha?: string
  hasPassword: boolean
  usePassword: boolean
}>({
  hasPassword: false,
  usePassword: true,
})

let isEmailVerified = _ref(false)
let isEmailCorporate = _ref(false)
const isEmailUnknown = _ref(false)

function onEmailVerified ({ isCorporate, hasPassword }: { isCorporate?: boolean; hasPassword?: boolean }) {
  if (isCorporate) {
    isEmailCorporate.value = true
  }
  else {
    isEmailVerified.value = true
  }

  credentials.value.hasPassword = Boolean(hasPassword)
}
</script>

<template>
  <CorporateLanding
    v-if="isEmailCorporate"
    @cancel="isEmailCorporate = false"
  />
  <SignupLanding
    v-else-if="isEmailUnknown"
    :email="credentials.email"
    @cancel="isEmailUnknown = false"
  />
  <div
    v-else
    class="bg-white relative paragraphs font-medium text-v2-secondary"
    data-qa="ConsumerLogin"
  >
    <GContainer class="relative">
      <div class="flex lg:flex-row items-center pt-16 pb-32 lg:h-800px">
        <div class="w-full lg:w-420px lg:pr-104px">
          <template v-if="!isEmailVerified">
            <h1 class="text-2xl mb-2">Log In or Sign Up</h1>
            <p class="text-lg">Hello there! Please enter your email to sign up or log in.</p>
            <EmailForm
              :credentials="credentials"
              @success="onEmailVerified"
              @unknown="isEmailUnknown = true"
            />
          </template>
          <template v-else>
            <h1 class="lg:text-4xl lg:mb-4">Welcome back!</h1>
            <p class="text-lg !mb-0 h-12">
              <template v-if="credentials.usePassword && credentials.hasPassword">
                Please enter your password to access your account.
              </template>
              <template v-else>
                Check your email for a verification code and enter it below.
              </template>
            </p>

            <div class="flex items-baseline gap-2 my-6 font-semibold">
              <span class="text-navy">{{ credentials.email }}</span>
              <button
                class="font-semibold text-sm text-v2-active hover:underline"
                type="button"
                @click="isEmailVerified = false"
              >
                Change
              </button>
            </div>

            <PasswordForm
              v-model:credentials="credentials"
              v-bind="$props"
              @cancel="isEmailVerified = false"
            />
          </template>
        </div>
      </div>
      <div
        :style="{ backgroundImage: `url(${loginBg})`}"
        class="
          hidden lg:block
          absolute w-1268px h-800px top-0 left-480px bg-no-repeat filter brightness-65
        "
      />
      <div class="hidden lg:block w-437px absolute top-400px left-600px text-white space-y-4">
        <div class="text-3xl font-semibold">
          Easy and convenient way to spread cheer, you get what you exactly want! As long as they give you enough!
        </div>
        <div class="text-lg font-semibold">
          Bob M.
        </div>
        <div class="text-lg">
          Recipient (Pottstown, Pennsylvania)
        </div>
      </div>
    </GContainer>
  </div>
</template>
