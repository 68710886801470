<script setup lang="ts">
import illustrationSvg from '@consumer/assets/illustrations/corp-landing.svg'

const emit = defineEmits<{
  cancel: []
}>()
</script>

<template>
  <GContainer class="py-10 lg:pt-20 lg:pb-44 paragraphs">
    <h1 class="text-4xl mb-20">Are you looking for Giftly Corporate?</h1>
    <GCard
      rounded
      class="flex flex-col items-center justify-center py-12 px-8 ring-1 ring-grey-400"
    >
      <img
        :src="illustrationSvg"
        name="new-misc-corp-landing"
        height="248"
        width="248"
      >
      <h2 class="text-center text-navy text-2xl mt-4 mb-4">
        Corporate
      </h2>
      <p class="text-lg text-grey-700 text-center">
        This email is associated with a Giftly Corporate Account, please login there.
      </p>
      <GButton
        primary
        size="large"
        as="a"
        name="login-corporate-landing-button"
        label="Go to Giftly Corporate"
        class="!w-320px"
        href="/corp/login"
      />
    </GCard>
    <div class="mt-6">
      <a class="link font-semibold" @click="emit('cancel')">Go Back</a>
    </div>
  </GContainer>
</template>
