<script setup lang="ts">
defineProps<{
  email?: string
}>()

interface Emit {
  (e: 'cancel'): void
}
const emit = defineEmits<Emit>()
</script>

<template>
  <GContainer class="py-10 lg:pt-20 lg:pb-44 paragraphs">
    <h1 class="text-4xl mb-10 lg:mb-20">How will you use Giftly?</h1>
    <div class="flex flex-col lg:flex-row gap-10">
      <GLink :href="`/signup?email=${encodeURIComponent(email ?? '')}`" class="flex-1 group hover:no-underline">
        <GCard
          rounded
          class="
            flex flex-col items-center justify-center py-12 px-8
            ring-1 ring-grey-400
            group-hover:ring-4 group-hover:ring-primary-base
          "
        >
          <GIcon
            name="new-users"
            color="grey-700"
            size="120px"
            class="stroke-4 group-hover:stroke-primary-base group-hover:fill-primary-base"
          />
          <h2 class="text-center text-grey-700 text-2xl mt-4 mb-4 group-hover:text-navy">
            Send Gifts to <br>
            Friends &amp; Family
          </h2>
          <p class="text-lg text-grey-700 text-center">
            Use a personal credit or debit card to send gifts to friends and family.
          </p>
        </GCard>
      </GLink>
      <a href="/corp/signup" class="flex-1 group hover:no-underline">
        <GCard
          rounded
          class="
            flex flex-col items-center justify-center py-12 px-8
            ring-1 ring-grey-400
            group-hover:ring-4 group-hover:ring-primary-base
          "
        >
          <GIcon
            name="new-building"
            color="grey-700"
            size="120px"
            class="stroke-4 group-hover:stroke-primary-base group-hover:fill-primary-base"
          />
          <h2 class="text-center text-grey-700 text-2xl mt-4 mb-4 group-hover:text-navy">
            Send Gifts to <br>
            Employees, Clients, Customers
          </h2>
          <p class="text-lg text-grey-700 text-center">
            Use a corporate credit or debit card to send gifts to employees, clients, customers.
          </p>
        </GCard>
      </a>
    </div>
    <div class="mt-12 text-center">
      <a class="link font-semibold" @click="emit('cancel')">Go Back</a>
    </div>
  </GContainer>
</template>
